import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { isProd, isStage } from 'config/env';
import { getConfigVar } from 'features/common/utils/config.utils';

/**
 * Initialize datadog RUM to start monitoring user sessions
 */
export function initDatadog(): void {
    if (isStage || isProd) {
        const commonConfig: RumInitConfiguration = {
            applicationId: getConfigVar(
                'REACT_APP_DATADOG_APPLICATION_ID',
            ) as string,
            clientToken: getConfigVar('REACT_APP_DATADOG_CLIENT_TOKEN') as string,
            site: 'datadoghq.com',
            service: 'google-my-business-web',
            // REACT_APP_VERSION gets the version in build time by the CI
            version: getConfigVar('REACT_APP_VERSION') as string,
            env: isStage ? 'staging' : 'production',
            sampleRate: 100,
            trackInteractions: true,
            trackSessionAcrossSubdomains: true,
            allowedTracingUrls: [
                (url) => {
                    return url.startsWith(
                        getConfigVar('REACT_APP_API_BASE_URL') as string,
                    );
                },
            ],
            defaultPrivacyLevel: 'mask-user-input',
        };

        datadogRum.init(commonConfig as RumInitConfiguration);
    }
}
