import { getConfigFlag } from 'features/common/utils/config.utils';

const env = process.env.NODE_ENV;

export const isProd = env === 'production';
export const isStage =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    env === 'staging' || getConfigFlag('REACT_APP_IS_QA');
export const isDev = env === 'development';
