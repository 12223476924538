/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

// import './index.scss';

/* To properly include fonts in webpack build from spoton-lib, we need to import this text mixin. */
import 'spoton-lib/styles/text.scss';

import Common from 'common.index';
import {
    configureCSRFToken,
    configureEphemeralApi,
} from 'features/common/utils/axios.utils';

type MfeProps = {
    isEphemeralEnv: boolean;
    CSRFToken?: string;
};

const reactLifeCycles = singleSpaReact<MfeProps>({
    React,
    ReactDOM,
    loadRootComponent: (props) => {
        return Promise.resolve(() => {
            return <Common />;
        });
    },
    errorBoundary(_err, _info, _props) {
        // https://reactjs.org/docs/error-boundaries.html
        return <div>This renders when a catastrophic error occurs</div>;
    },
    renderType: 'render',
});

// Single SPA lifecycle methods
export const bootstrap: typeof reactLifeCycles['bootstrap'] = (props) => {
    /**
     * This is a workaround that allows us to have our app working against a dashboard ephemeral env.
     * What it does is pretty simple, it updates the base url for our API to use the internal *.spoton.sh
     * that way both the ephemeral and our API work on the same domain and we don't get blocked by auth and CORS verifications
     * */
    if (props.isEphemeralEnv) {
        configureEphemeralApi();
    }

    if (props.CSRFToken) {
        configureCSRFToken(props.CSRFToken);
    }

    return reactLifeCycles.bootstrap(props);
};
export const mount = reactLifeCycles.mount;
export const unmount = reactLifeCycles.unmount;
